<template>
    <v-sheet 
        class="d-flex mx-2 align-center pa-2 justify-center"
        color="grey lighten-3"
        rounded=""
    >
        <v-icon class="mx-2">mdi-layers-off</v-icon>
        <div class="text-h6 mx-2 grey--text text--darken-1">Empty list</div>
    </v-sheet>
</template>

<script>
export default {
    name: 'EmptyListComponent',
    data: () => ({

    })
}
</script>

<style>

</style>